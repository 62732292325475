var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "mb-0 table-card",
      staticStyle: { border: "0" },
      attrs: { "header-tag": "header", "no-body": "" },
      scopedSlots: _vm._u(
        [
          !_vm.noHeader
            ? {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "b-row",
                      { attrs: { "align-v": "center" } },
                      [
                        _vm.title
                          ? _c(
                              "b-col",
                              { attrs: { cols: _vm.hasSearch ? "auto" : "" } },
                              [_c("strong", [_vm._v(_vm._s(_vm.title))])]
                            )
                          : _vm._e(),
                        _vm.hasSearch
                          ? _c("b-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "input-group input-group-flush",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "input-group-prepend" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "input-group-text" },
                                        [
                                          _c("i", {
                                            staticClass: "fe fe-search",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.filter,
                                        expression: "filter",
                                      },
                                    ],
                                    staticClass: "form-control list-search",
                                    attrs: {
                                      type: "search",
                                      placeholder: "Search",
                                    },
                                    domProps: { value: _vm.filter },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.filter = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "auto" } },
                          [
                            _c(
                              "b-row",
                              { attrs: { "align-v": "center" } },
                              [
                                _c(
                                  "b-col",
                                  [
                                    _vm.filter
                                      ? [
                                          _vm._v("Showing "),
                                          _c("b", [_vm._v(_vm._s(_vm.rows))]),
                                          _vm.rows == 1 || _vm.rows == 0
                                            ? [_vm._v(" result")]
                                            : [_vm._v(" results")],
                                          _vm._v(
                                            " for “" + _vm._s(_vm.filter) + "”"
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _c("b-table", {
        ref: "selectableTable",
        class: ["table-nowrap billing-table", _vm.className],
        attrs: {
          responsive: "",
          "select-mode": "single",
          selectable: _vm.isSelectable,
          "sticky-header": _vm.scrollable ? _vm.scrollHeight : false,
          fields: _vm.fields,
          items: _vm.items,
          filter: _vm.hasSearch ? _vm.filter : "",
          "current-page": _vm.hasPagination ? _vm.currentPage : "",
          "per-page": _vm.hasPagination ? _vm.itemPerPage : "",
          "sort-compare": _vm.sortCompare,
        },
        on: { filtered: _vm.onFiltered, "row-selected": _vm.onRowSelected },
        nativeOn: {
          scroll: function ($event) {
            return _vm.onScroll.apply(null, arguments)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(status)",
            fn: function (data) {
              return [
                _c("p", { staticClass: "mb-0" }, [
                  data.item.status === "Follow-up Completed"
                    ? _c("span", { staticClass: "mr-3 dot dot-green" })
                    : data.item.status === "Inactive"
                    ? _c("span", { staticClass: "mr-3 dot dot-red" })
                    : data.item.status === "New"
                    ? _c("span", { staticClass: "mr-3 dot dot-yellow" })
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(data.item.status))]),
                ]),
              ]
            },
          },
          {
            key: "cell(patientstatus)",
            fn: function (data) {
              return [
                _c("p", { staticClass: "mb-0" }, [
                  data.item.patientstatus === "Follow-up Completed"
                    ? _c("span", { staticClass: "mr-3 dot dot-green" })
                    : data.item.patientstatus === "Inactive"
                    ? _c("span", { staticClass: "mr-3 dot dot-red" })
                    : data.item.patientstatus === "New"
                    ? _c("span", { staticClass: "mr-3 dot dot-yellow" })
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(data.item.patientstatus))]),
                ]),
              ]
            },
          },
          {
            key: "cell(payoutstatus)",
            fn: function (data) {
              return [
                _c("p", { staticClass: "mb-0" }, [
                  data.item.payoutstatus === "Cleared"
                    ? _c("span", { staticClass: "mr-3 dot dot-green" })
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(data.item.payoutstatus))]),
                ]),
              ]
            },
          },
          {
            key: "cell(download)",
            fn: function (data) {
              return [
                _c(
                  "a",
                  {
                    staticClass: "text-secondary tw-cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.onDownloadInvoice(data.item.id)
                      },
                    },
                  },
                  [_vm._v(" Download ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }