var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-billing p-4" },
    [
      _c(
        "p",
        {
          staticClass: "text-muted mb-3",
          staticStyle: { "font-size": "14px" },
        },
        [_vm._t("card-title")],
        2
      ),
      _c(
        "h1",
        { staticClass: "mb-2", staticStyle: { "font-weight": "600" } },
        [_vm._t("card-text")],
        2
      ),
      _vm._t("card-subtext"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }