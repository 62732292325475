var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        {
          staticClass: "pl-0 d-flex justify-content-between align-items-center",
          attrs: { cols: "12" },
        },
        [
          _c("div", [
            _c("h2", { staticClass: "my-5 bold primary-color" }, [
              _vm._v("My Earnings"),
            ]),
          ]),
          _vm.currentView === "history"
            ? _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "pb-0",
                      staticStyle: { "box-shadow": "none !important" },
                      attrs: { variant: "link", block: "" },
                      on: {
                        click: function ($event) {
                          _vm.currentView = "earnings"
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-secondary d-inline-block btn-patient-reply",
                        },
                        [_vm._v(" View earnings dashboard ")]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentView === "earnings",
              expression: "currentView === 'earnings'",
            },
          ],
          attrs: { cols: "12" },
        },
        [
          _c(
            "b-row",
            { staticClass: "content" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "12", lg: "8", xl: "9" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "px-4 mb-3" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "pl-0 d-flex align-items-stretch",
                          attrs: { cols: "12", xl: "6" },
                        },
                        [
                          _c("CardEarningStat", {
                            scopedSlots: _vm._u([
                              {
                                key: "card-title",
                                fn: function () {
                                  return [_vm._v(" Total Money Collected ")]
                                },
                                proxy: true,
                              },
                              {
                                key: "card-text",
                                fn: function () {
                                  return [
                                    _vm.isLoading && _vm.currentInvoice
                                      ? _c("b-skeleton", {
                                          attrs: {
                                            width: "20%",
                                            height: "30px",
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.parseCurrencyUSD(
                                                  _vm.currentInvoice.earning
                                                    .alltime
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "card-subtext",
                                fn: function () {
                                  return [
                                    _vm.isLoading && _vm.currentInvoice
                                      ? _c("b-skeleton", {
                                          attrs: {
                                            width: "40%",
                                            height: "15px",
                                          },
                                        })
                                      : _c("p", { staticClass: "mb-2" }, [
                                          _vm._v(
                                            " since " +
                                              _vm._s(
                                                _vm.parseDate(
                                                  _vm.currentInvoice
                                                    .doctorstartdate,
                                                  "MMMM d, yyyy"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "pl-0 d-flex align-items-stretch",
                          attrs: { cols: "12", xl: "6" },
                        },
                        [
                          _c("CardEarningStat", {
                            scopedSlots: _vm._u([
                              {
                                key: "card-title",
                                fn: function () {
                                  return [
                                    _vm._v(" Total Earned this Billing Cycle "),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "card-text",
                                fn: function () {
                                  return [
                                    _vm.isLoading && _vm.currentInvoice
                                      ? _c("b-skeleton", {
                                          attrs: {
                                            width: "20%",
                                            height: "30px",
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.parseCurrencyUSD(
                                                  _vm.currentInvoice.earning
                                                    .total
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "card-subtext",
                                fn: function () {
                                  return [
                                    _vm.isLoading && _vm.currentInvoice
                                      ? _c("b-skeleton", {
                                          attrs: {
                                            width: "40%",
                                            height: "15px",
                                          },
                                        })
                                      : _c("p", { staticClass: "mb-2" }, [
                                          _vm._v(
                                            " from " +
                                              _vm._s(
                                                _vm.parseDate(
                                                  _vm.currentInvoice
                                                    .coveredperiod
                                                    .startdate_unix,
                                                  "MMMM do",
                                                  true
                                                )
                                              ) +
                                              " - " +
                                              _vm._s(
                                                _vm.parseDate(
                                                  _vm.currentInvoice
                                                    .coveredperiod.enddate_unix,
                                                  "MMMM do yyyy",
                                                  true
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.currentInvoice
                    ? _c(
                        "b-row",
                        { staticClass: "px-4" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "pl-0 d-flex align-items-stretch",
                              attrs: { cols: "12", xl: "3" },
                            },
                            [
                              _c("CardEarningStat", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "card-title",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " Total Patients Verified - All Time "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "card-text",
                                      fn: function () {
                                        return [
                                          _vm.isLoading && _vm.currentInvoice
                                            ? _c("b-skeleton", {
                                                attrs: {
                                                  width: "20%",
                                                  height: "30px",
                                                },
                                              })
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.patientAllTimeCount
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1249115911
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "pl-0 d-flex align-items-stretch",
                              attrs: { cols: "12", xl: "3" },
                            },
                            [
                              _c("CardEarningStat", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "card-title",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " Total New Patients this Period "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "card-text",
                                      fn: function () {
                                        return [
                                          _vm.isLoading && _vm.currentInvoice
                                            ? _c("b-skeleton", {
                                                attrs: {
                                                  width: "20%",
                                                  height: "30px",
                                                },
                                              })
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.currentInvoice
                                                        .patients.new
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3701880391
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "pl-0 d-flex align-items-stretch",
                              attrs: { cols: "12", xl: "3" },
                            },
                            [
                              _c("CardEarningStat", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "card-title",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " Follow-ups Completed this Period "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "card-text",
                                      fn: function () {
                                        return [
                                          _vm.isLoading && _vm.currentInvoice
                                            ? _c("b-skeleton", {
                                                attrs: {
                                                  width: "20%",
                                                  height: "30px",
                                                },
                                              })
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.currentInvoice
                                                        .patients.followup
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1967573110
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "pl-0 d-flex align-items-stretch",
                              attrs: { cols: "12", xl: "3" },
                            },
                            [
                              _c("CardEarningStat", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "card-title",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " Total Paid Messages this Period "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "card-text",
                                      fn: function () {
                                        return [
                                          _vm.isLoading && _vm.currentInvoice
                                            ? _c("b-skeleton", {
                                                attrs: {
                                                  width: "20%",
                                                  height: "30px",
                                                },
                                              })
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.currentInvoice
                                                        .patients
                                                        .messageadjusted
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  72836756
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("b-row", { staticClass: "px-4 py-5" }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "div",
                        {
                          staticClass: "mr-4",
                          class:
                            _vm.earningsTableFilter === "All"
                              ? ""
                              : "text-muted",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              _vm.earningsTableFilter = "All"
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v(" All Patients ")]),
                          _c(
                            "span",
                            {
                              staticClass: "status-pill",
                              class:
                                _vm.earningsTableFilter === "All"
                                  ? "pill-dark-cyan"
                                  : "",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.patientAllTimeCount) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mr-4",
                          class:
                            _vm.earningsTableFilter === "New"
                              ? ""
                              : "text-muted",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              _vm.earningsTableFilter = "New"
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v(" New Patients this Period ")]),
                          _c(
                            "span",
                            {
                              staticClass: "status-pill",
                              class:
                                _vm.earningsTableFilter === "New"
                                  ? "pill-dark-cyan"
                                  : "",
                            },
                            [_vm._v(" " + _vm._s(_vm.newPatientsCount) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mr-4",
                          class:
                            _vm.earningsTableFilter === "Follow-up"
                              ? ""
                              : "text-muted",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              _vm.earningsTableFilter = "Follow-up"
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v(" Follow-ups Completed ")]),
                          _c(
                            "span",
                            {
                              staticClass: "status-pill",
                              class:
                                _vm.earningsTableFilter === "Follow-up"
                                  ? "pill-dark-cyan"
                                  : "",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.followupPatientsCount) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mr-4",
                          class:
                            _vm.earningsTableFilter === "Paid Messages"
                              ? ""
                              : "text-muted",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              _vm.earningsTableFilter = "Paid Messages"
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v(" Paid Messages ")]),
                          _vm.isLoading && _vm.currentInvoice
                            ? _c(
                                "span",
                                {
                                  staticClass: "status-pill",
                                  class:
                                    _vm.earningsTableFilter === "Paid Messages"
                                      ? "pill-dark-cyan"
                                      : "",
                                },
                                [_vm._v(" 0 ")]
                              )
                            : _c(
                                "span",
                                {
                                  staticClass: "status-pill",
                                  class:
                                    _vm.earningsTableFilter === "Paid Messages"
                                      ? "pill-dark-cyan"
                                      : "",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.currentInvoice.patients
                                          .messageadjusted
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm.earningsTableFilter === "All"
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "px-0", attrs: { cols: "12" } },
                            [
                              _c("TableEarning", {
                                attrs: {
                                  fields: _vm.allTimePatientsFields,
                                  items: _vm.allTimePatientsList,
                                  "no-header": true,
                                  "scroll-height": "calc(100vh - 70vh)",
                                  scrollable: "",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "tw-py-2 tw-flex tw-mx-auto tw-items-center tw-justify-center tw-bg-transparent",
                                },
                                [
                                  _vm.patientAllTimeCount > 0
                                    ? _c("b-pagination", {
                                        attrs: {
                                          "total-rows": _vm.patientAllTimeCount,
                                          "per-page": 10,
                                        },
                                        on: {
                                          "page-click":
                                            _vm.handlePaginationClick,
                                        },
                                        model: {
                                          value: _vm.currentPageAllTime,
                                          callback: function ($$v) {
                                            _vm.currentPageAllTime = $$v
                                          },
                                          expression: "currentPageAllTime",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm.earningsTableFilter === "New"
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "px-0", attrs: { cols: "12" } },
                            [
                              _c("TableEarning", {
                                attrs: {
                                  fields: _vm.newPatientsFields,
                                  items: _vm.cleanedNewPatientList,
                                  "no-header": true,
                                  "scroll-height": "calc(100vh - 70vh)",
                                  scrollable: "",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "tw-py-2 tw-flex tw-mx-auto tw-items-center tw-justify-center tw-bg-transparent",
                                },
                                [
                                  _vm.newPatientsCount > 0
                                    ? _c("b-pagination", {
                                        attrs: {
                                          "total-rows": _vm.newPatientsCount,
                                          "per-page": 10,
                                        },
                                        on: {
                                          "page-click":
                                            _vm.handlePaginationClick,
                                        },
                                        model: {
                                          value: _vm.currentPageNewPatients,
                                          callback: function ($$v) {
                                            _vm.currentPageNewPatients = $$v
                                          },
                                          expression: "currentPageNewPatients",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm.earningsTableFilter === "Follow-up"
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "px-0", attrs: { cols: "12" } },
                            [
                              _c("TableEarning", {
                                attrs: {
                                  fields: _vm.followupPatientsField,
                                  items: _vm.cleanedFollowupPatientList,
                                  "no-header": true,
                                  "scroll-height": "calc(100vh - 70vh)",
                                  scrollable: "",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "tw-py-2 tw-flex tw-mx-auto tw-items-center tw-justify-center tw-bg-transparent",
                                },
                                [
                                  _vm.followupPatientsCount > 0
                                    ? _c("b-pagination", {
                                        attrs: {
                                          "total-rows":
                                            _vm.followupPatientsCount,
                                          "per-page": 10,
                                        },
                                        on: {
                                          "page-click":
                                            _vm.handlePaginationClick,
                                        },
                                        model: {
                                          value:
                                            _vm.currentPageFollowupPatients,
                                          callback: function ($$v) {
                                            _vm.currentPageFollowupPatients =
                                              $$v
                                          },
                                          expression:
                                            "currentPageFollowupPatients",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm.earningsTableFilter === "Paid Messages"
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "px-0", attrs: { cols: "12" } },
                            [
                              _c("TableEarning", {
                                attrs: {
                                  fields: _vm.paidMessagesFields,
                                  items: _vm.cleanedPaidMessagesList,
                                  "no-header": true,
                                  "scroll-height": "calc(100vh - 70vh)",
                                  scrollable: "",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "tw-py-2 tw-flex tw-mx-auto tw-items-center tw-justify-center tw-bg-transparent",
                                },
                                [
                                  _vm.paidMessagesCount > 0
                                    ? _c("b-pagination", {
                                        attrs: {
                                          "total-rows": _vm.paidMessagesCount,
                                          "per-page": 10,
                                        },
                                        on: {
                                          "page-click":
                                            _vm.handlePaginationClick,
                                        },
                                        model: {
                                          value: _vm.currentPagePaidMessages,
                                          callback: function ($$v) {
                                            _vm.currentPagePaidMessages = $$v
                                          },
                                          expression: "currentPagePaidMessages",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "12", lg: "4", xl: "3" } },
                [
                  _c("b-card", { staticClass: "invoice-overview mb-0" }, [
                    _vm.isLoading
                      ? _c(
                          "div",
                          [
                            _c("b-skeleton", {
                              staticClass: "mb-4",
                              attrs: { width: "30%", height: "30px" },
                            }),
                            _c("b-skeleton", { attrs: { height: "30px" } }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c("h3", { staticClass: "mb-4 bold" }, [
                              _vm._v("Invoice Overview"),
                            ]),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  pill: "",
                                  variant: "secondary",
                                  block: "",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.currentView = "history"
                                  },
                                },
                              },
                              [_vm._v(" View invoice history ")]
                            ),
                          ],
                          1
                        ),
                    _vm.previousInvoice
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "py-6 d-flex justify-content-between align-items-center",
                            },
                            [
                              _c("h3", { staticClass: "mb-0" }, [
                                _vm._v("Previous Invoice"),
                              ]),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.parseDate(
                                        _vm.previousInvoice.coveredperiod
                                          .startdate_unix,
                                        "MM/dd/yy",
                                        true
                                      )
                                    ) +
                                    " - " +
                                    _vm._s(
                                      _vm.parseDate(
                                        _vm.previousInvoice.coveredperiod
                                          .enddate_unix,
                                        "MM/dd/yy",
                                        true
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "mb-4 pb-4",
                              staticStyle: {
                                "border-bottom": "1px solid #ff7a37",
                                "max-height": "1000px",
                                "overflow-y": "auto",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pb-2 d-flex justify-content-between align-items-end",
                                },
                                [
                                  _c("div", [
                                    _c("small", { staticClass: "text-muted" }, [
                                      _vm._v("New Patients"),
                                    ]),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _vm._v("Total"),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.previousInvoice.patients.new)
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pb-5 d-flex justify-content-between align-items-end",
                                },
                                [
                                  _c("div", [
                                    _c("small", { staticClass: "text-muted" }, [
                                      _vm._v("New Patients"),
                                    ]),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _vm._v("Amount Earned"),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.parseCurrencyUSD(
                                          _vm.previousInvoice.earning.new
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pb-2 d-flex justify-content-between align-items-end",
                                },
                                [
                                  _c("div", [
                                    _c("small", { staticClass: "text-muted" }, [
                                      _vm._v("Follow-ups Completed"),
                                    ]),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _vm._v("Total"),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.previousInvoice.patients.followup
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pb-5 d-flex justify-content-between align-items-end",
                                },
                                [
                                  _c("div", [
                                    _c("small", { staticClass: "text-muted" }, [
                                      _vm._v("Follow-ups Completed"),
                                    ]),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _vm._v("Amount Earned"),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.parseCurrencyUSD(
                                            _vm.previousInvoice.earning.followup
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pb-2 d-flex justify-content-between align-items-end",
                                },
                                [
                                  _c("div", [
                                    _c("small", { staticClass: "text-muted" }, [
                                      _vm._v("Paid Messages"),
                                    ]),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _vm._v("Total"),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.previousInvoice.patients
                                          .messageadjusted
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pb-5 d-flex justify-content-between align-items-end",
                                },
                                [
                                  _c("div", [
                                    _c("small", { staticClass: "text-muted" }, [
                                      _vm._v("Paid Messages"),
                                    ]),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _vm._v("Amount Earned"),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.parseCurrencyUSD(
                                            _vm.previousInvoice.earning
                                              .messageadjusted || 0
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mb-6 d-flex justify-content-between align-items-center",
                            },
                            [
                              _c("p", { staticClass: "mb-0" }, [
                                _vm._v("Total"),
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parseCurrencyUSD(
                                      _vm.previousInvoice.earning.total
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentView === "history",
              expression: "currentView === 'history'",
            },
          ],
          staticClass: "mb-6",
          attrs: { cols: "12" },
        },
        [
          _c(
            "b-row",
            { staticClass: "content" },
            [
              _vm.currentInvoice
                ? _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-row",
                        { staticClass: "px-2 mb-3" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "pl-0 d-flex align-items-stretch",
                              attrs: { cols: "12", md: "6", xl: "3" },
                            },
                            [
                              _c("CardEarningStat", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "card-title",
                                      fn: function () {
                                        return [
                                          _vm._v(" Total Money Collected "),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "card-text",
                                      fn: function () {
                                        return [
                                          _vm.isLoading && _vm.currentInvoice
                                            ? _c("b-skeleton", {
                                                attrs: {
                                                  width: "20%",
                                                  height: "30px",
                                                },
                                              })
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.parseCurrencyUSD(
                                                        _vm.currentInvoice
                                                          .earning.alltime
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "card-subtext",
                                      fn: function () {
                                        return [
                                          _vm.isLoading && _vm.currentInvoice
                                            ? _c("b-skeleton", {
                                                attrs: {
                                                  width: "40%",
                                                  height: "15px",
                                                },
                                              })
                                            : _c("p", { staticClass: "mb-2" }, [
                                                _vm._v(
                                                  " since " +
                                                    _vm._s(
                                                      _vm.parseDate(
                                                        _vm.currentInvoice
                                                          .doctorstartdate,
                                                        "MMMM d, yyyy"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1757770006
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "pl-0 d-flex align-items-stretch",
                              attrs: { cols: "12", md: "6", xl: "3" },
                            },
                            [
                              _c("CardEarningStat", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "card-title",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " Total Patients Verified - All Time "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "card-text",
                                      fn: function () {
                                        return [
                                          _vm.isLoading && _vm.currentInvoice
                                            ? _c("b-skeleton", {
                                                attrs: {
                                                  width: "20%",
                                                  height: "30px",
                                                },
                                              })
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.patientAllTimeCount
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1249115911
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "pl-0 d-flex align-items-stretch",
                              attrs: { cols: "12", md: "6", xl: "3" },
                            },
                            [
                              _c("CardEarningStat", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "card-title",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " Total New Patients this Period "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "card-text",
                                      fn: function () {
                                        return [
                                          _vm.isLoading && _vm.currentInvoice
                                            ? _c("b-skeleton", {
                                                attrs: {
                                                  width: "20%",
                                                  height: "30px",
                                                },
                                              })
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.currentInvoice
                                                        .patients.new
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3701880391
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "pl-0 d-flex align-items-stretch",
                              attrs: { cols: "12", md: "6", xl: "3" },
                            },
                            [
                              _c("CardEarningStat", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "card-title",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " Follow-ups Completed this Period "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "card-text",
                                      fn: function () {
                                        return [
                                          _vm.isLoading && _vm.currentInvoice
                                            ? _c("b-skeleton", {
                                                attrs: {
                                                  width: "20%",
                                                  height: "30px",
                                                },
                                              })
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.currentInvoice
                                                        .patients.followup
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1967573110
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-col",
                { staticStyle: { padding: "20px" }, attrs: { cols: "12" } },
                [
                  _c("div", { staticClass: "pt-4 pb-2" }, [
                    _c("h3", { staticClass: "mb-0" }, [
                      _vm._v("Current Invoice"),
                    ]),
                    _vm.currentInvoice.coveredperiod.startdate_unix
                      ? _c("small", { staticClass: "text-muted" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.parseDate(
                                  _vm.currentInvoice.coveredperiod
                                    .startdate_unix,
                                  "MM/dd/yy",
                                  true
                                )
                              ) +
                              " - " +
                              _vm._s(
                                _vm.parseDate(
                                  _vm.currentInvoice.coveredperiod.enddate_unix,
                                  "MM/dd/yy",
                                  true
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("TableEarning", {
                    attrs: {
                      fields: _vm.currentInvoiceFields,
                      items: _vm.currentPatientsInvoiceItems,
                      "no-header": true,
                      "scroll-height": "calc(100vh - 70vh)",
                      scrollable: "",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-end mt-4 pr-4" },
                    [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c("h3", { staticClass: "mb-0 mr-6" }, [
                          _vm._v("Total"),
                        ]),
                        _c("h2", { staticClass: "mb-0 text-primary" }, [
                          _vm._v(
                            " " + _vm._s(_vm.totalPatientsInvoiceAmount) + " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("div", { staticClass: "pb-2" }, [
                    _c("h3", { staticClass: "mb-0" }, [
                      _vm._v("Invoice History"),
                    ]),
                  ]),
                  _c("TableEarning", {
                    attrs: {
                      fields: _vm.invoiceHistoryFields,
                      items: _vm.invoiceHistoryItems,
                      "no-header": true,
                      "scroll-height": "calc(100vh - 70vh)",
                      scrollable: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }