<template>
  <div class="card-billing p-4">
    <p class="text-muted mb-3" style="font-size: 14px">
      <slot name="card-title"></slot>
    </p>
    <h1 class="mb-2" style="font-weight: 600">
      <slot name="card-text"></slot>
    </h1>
    <slot name="card-subtext"></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.card-billing {
  background-color: $white;
  width: 100%;
}

.darkmode .card-billing {
  background-color: rgba(255, 236, 202, 0.05);
}
</style>
