<template>
  <b-row>
    <b-col
      cols="12"
      class="pl-0 d-flex justify-content-between align-items-center"
    >
      <div>
        <h2 class="my-5 bold primary-color">My Earnings</h2>
      </div>
      <div v-if="currentView === 'history'">
        <b-button
          variant="link"
          block
          class="pb-0"
          style="box-shadow: none !important"
          @click="currentView = 'earnings'"
        >
          <span class="text-secondary d-inline-block btn-patient-reply">
            View earnings dashboard
          </span>
        </b-button>
      </div>
    </b-col>
    <b-col cols="12" v-show="currentView === 'earnings'">
      <b-row class="content">
        <b-col cols="12" md="12" lg="8" xl="9">
          <b-row class="px-4 mb-3">
            <b-col cols="12" xl="6" class="pl-0 d-flex align-items-stretch">
              <CardEarningStat>
                <template #card-title> Total Money Collected </template>
                <template #card-text>
                  <b-skeleton
                    v-if="isLoading && currentInvoice"
                    width="20%"
                    height="30px"
                  ></b-skeleton>
                  <span v-else>
                    {{ parseCurrencyUSD(currentInvoice.earning.alltime) }}
                  </span>
                </template>
                <template #card-subtext>
                  <b-skeleton
                    v-if="isLoading && currentInvoice"
                    width="40%"
                    height="15px"
                  ></b-skeleton>
                  <p v-else class="mb-2">
                    since
                    {{
                      parseDate(currentInvoice.doctorstartdate, "MMMM d, yyyy")
                    }}
                  </p>
                </template>
              </CardEarningStat>
            </b-col>
            <b-col cols="12" xl="6" class="pl-0 d-flex align-items-stretch">
              <CardEarningStat>
                <template #card-title>
                  Total Earned this Billing Cycle
                </template>
                <template #card-text>
                  <b-skeleton
                    v-if="isLoading && currentInvoice"
                    width="20%"
                    height="30px"
                  ></b-skeleton>
                  <span v-else>
                    {{ parseCurrencyUSD(currentInvoice.earning.total) }}
                  </span>
                </template>
                <template #card-subtext>
                  <b-skeleton
                    v-if="isLoading && currentInvoice"
                    width="40%"
                    height="15px"
                  ></b-skeleton>
                  <p v-else class="mb-2">
                    from
                    {{
                      parseDate(
                        currentInvoice.coveredperiod.startdate_unix,
                        "MMMM do",
                        true
                      )
                    }}
                    -
                    {{
                      parseDate(
                        currentInvoice.coveredperiod.enddate_unix,
                        "MMMM do yyyy",
                        true
                      )
                    }}
                  </p>
                </template>
              </CardEarningStat>
            </b-col>
          </b-row>
          <b-row class="px-4" v-if="currentInvoice">
            <b-col cols="12" xl="3" class="pl-0 d-flex align-items-stretch">
              <CardEarningStat>
                <template #card-title>
                  Total Patients Verified - All Time
                </template>
                <template #card-text>
                  <b-skeleton
                    v-if="isLoading && currentInvoice"
                    width="20%"
                    height="30px"
                  ></b-skeleton>
                  <span v-else>
                    {{ patientAllTimeCount }}
                  </span>
                </template>
              </CardEarningStat>
            </b-col>
            <b-col cols="12" xl="3" class="pl-0 d-flex align-items-stretch">
              <CardEarningStat>
                <template #card-title>
                  Total New Patients this Period
                </template>
                <template #card-text>
                  <b-skeleton
                    v-if="isLoading && currentInvoice"
                    width="20%"
                    height="30px"
                  ></b-skeleton>
                  <span v-else>
                    {{ currentInvoice.patients.new }}
                  </span>
                </template>
                <!-- <template #card-subtext>
                  <p class="mb-2 text-muted">
                    <span style="font-weight: 600">126 </span>
                    <span>Total New Patients Last Period</span>
                  </p>
                </template> -->
              </CardEarningStat>
            </b-col>
            <b-col cols="12" xl="3" class="pl-0 d-flex align-items-stretch">
              <CardEarningStat>
                <template #card-title>
                  Follow-ups Completed this Period
                </template>
                <template #card-text>
                  <b-skeleton
                    v-if="isLoading && currentInvoice"
                    width="20%"
                    height="30px"
                  ></b-skeleton>
                  <span v-else>
                    {{ currentInvoice.patients.followup }}
                  </span>
                </template>
                <!-- <template #card-subtext>
                  <p class="mb-2 text-muted">
                    <span style="font-weight: 600">126 </span>
                    <span>Total Active Patients Last Period</span>
                  </p>
                </template> -->
              </CardEarningStat>
            </b-col>
            <b-col cols="12" xl="3" class="pl-0 d-flex align-items-stretch">
              <CardEarningStat>
                <template #card-title>
                  Total Paid Messages this Period
                </template>
                <template #card-text>
                  <b-skeleton
                    v-if="isLoading && currentInvoice"
                    width="20%"
                    height="30px"
                  ></b-skeleton>
                  <span v-else>
                    {{ currentInvoice.patients.messageadjusted }}
                  </span>
                </template>
              </CardEarningStat>
            </b-col>
          </b-row>
          <b-row class="px-4 py-5">
            <div class="d-flex align-items-center">
              <div
                class="mr-4"
                style="cursor: pointer"
                :class="earningsTableFilter === 'All' ? '' : 'text-muted'"
                @click="earningsTableFilter = 'All'"
              >
                <span> All Patients </span>
                <span
                  class="status-pill"
                  :class="earningsTableFilter === 'All' ? 'pill-dark-cyan' : ''"
                >
                  {{ patientAllTimeCount }}
                </span>
              </div>
              <div
                class="mr-4"
                style="cursor: pointer"
                :class="earningsTableFilter === 'New' ? '' : 'text-muted'"
                @click="earningsTableFilter = 'New'"
              >
                <span> New Patients this Period </span>
                <span
                  class="status-pill"
                  :class="earningsTableFilter === 'New' ? 'pill-dark-cyan' : ''"
                >
                  {{ newPatientsCount }}
                </span>
              </div>
              <div
                class="mr-4"
                style="cursor: pointer"
                :class="earningsTableFilter === 'Follow-up' ? '' : 'text-muted'"
                @click="earningsTableFilter = 'Follow-up'"
              >
                <span> Follow-ups Completed </span>
                <span
                  class="status-pill"
                  :class="
                    earningsTableFilter === 'Follow-up' ? 'pill-dark-cyan' : ''
                  "
                >
                  {{ followupPatientsCount }}
                </span>
              </div>
              <div
                class="mr-4"
                style="cursor: pointer"
                :class="
                  earningsTableFilter === 'Paid Messages' ? '' : 'text-muted'
                "
                @click="earningsTableFilter = 'Paid Messages'"
              >
                <span> Paid Messages </span>
                <span
                  v-if="isLoading && currentInvoice"
                  class="status-pill"
                  :class="
                    earningsTableFilter === 'Paid Messages'
                      ? 'pill-dark-cyan'
                      : ''
                  "
                >
                  0
                </span>
                <span
                  v-else
                  class="status-pill"
                  :class="
                    earningsTableFilter === 'Paid Messages'
                      ? 'pill-dark-cyan'
                      : ''
                  "
                >
                  {{ currentInvoice.patients.messageadjusted }}
                </span>
              </div>
              <!-- <div
                class="mr-4"
                style="cursor: pointer"
                :class="earningsTableFilter === 'Inactive' ? '' : 'text-muted'"
                @click="earningsTableFilter = 'Inactive'"
              >
                <span> Inactive Patients </span>
                <span
                  class="status-pill"
                  :class="
                    earningsTableFilter === 'Inactive' ? 'pill-dark-cyan' : ''
                  "
                >
                  {{ inactivePatientList.length }}
                </span>
              </div> -->
            </div>
          </b-row>
          <b-row v-if="earningsTableFilter === 'All'">
            <b-col cols="12" class="px-0">
              <TableEarning
                :fields="allTimePatientsFields"
                :items="allTimePatientsList"
                :no-header="true"
                scroll-height="calc(100vh - 70vh)"
                scrollable
              />
              <div
                class="
                  tw-py-2
                  tw-flex
                  tw-mx-auto
                  tw-items-center
                  tw-justify-center
                  tw-bg-transparent
                "
              >
                <b-pagination
                  v-if="patientAllTimeCount > 0"
                  v-model="currentPageAllTime"
                  :total-rows="patientAllTimeCount"
                  :per-page="10"
                  @page-click="handlePaginationClick"
                ></b-pagination>
              </div>
            </b-col>
          </b-row>
          <b-row v-else-if="earningsTableFilter === 'New'">
            <b-col cols="12" class="px-0">
              <TableEarning
                :fields="newPatientsFields"
                :items="cleanedNewPatientList"
                :no-header="true"
                scroll-height="calc(100vh - 70vh)"
                scrollable
              />
              <div
                class="
                  tw-py-2
                  tw-flex
                  tw-mx-auto
                  tw-items-center
                  tw-justify-center
                  tw-bg-transparent
                "
              >
                <b-pagination
                  v-if="newPatientsCount > 0"
                  v-model="currentPageNewPatients"
                  :total-rows="newPatientsCount"
                  :per-page="10"
                  @page-click="handlePaginationClick"
                ></b-pagination>
              </div>
            </b-col>
          </b-row>
          <b-row v-else-if="earningsTableFilter === 'Follow-up'">
            <b-col cols="12" class="px-0">
              <TableEarning
                :fields="followupPatientsField"
                :items="cleanedFollowupPatientList"
                :no-header="true"
                scroll-height="calc(100vh - 70vh)"
                scrollable
              />
              <div
                class="
                  tw-py-2
                  tw-flex
                  tw-mx-auto
                  tw-items-center
                  tw-justify-center
                  tw-bg-transparent
                "
              >
                <b-pagination
                  v-if="followupPatientsCount > 0"
                  v-model="currentPageFollowupPatients"
                  :total-rows="followupPatientsCount"
                  :per-page="10"
                  @page-click="handlePaginationClick"
                ></b-pagination>
              </div>
            </b-col>
          </b-row>
          <b-row v-else-if="earningsTableFilter === 'Paid Messages'">
            <b-col cols="12" class="px-0">
              <TableEarning
                :fields="paidMessagesFields"
                :items="cleanedPaidMessagesList"
                :no-header="true"
                scroll-height="calc(100vh - 70vh)"
                scrollable
              />
              <div
                class="
                  tw-py-2
                  tw-flex
                  tw-mx-auto
                  tw-items-center
                  tw-justify-center
                  tw-bg-transparent
                "
              >
                <b-pagination
                  v-if="paidMessagesCount > 0"
                  v-model="currentPagePaidMessages"
                  :total-rows="paidMessagesCount"
                  :per-page="10"
                  @page-click="handlePaginationClick"
                ></b-pagination>
              </div>
            </b-col>
          </b-row>

          <!-- <b-row
            v-else-if="
              filteredPatientList.length > 0 &&
              earningsTableFilter !== 'Paid Messages'
            "
          >
            <b-col cols="12" class="px-0">
              <TableEarning
                :fields="allTimePatientsFields"
                :items="filteredPatientList"
                :no-header="true"
                scroll-height="calc(100vh - 70vh)"
                scrollable
              />
            </b-col>
          </b-row> -->
        </b-col>
        <b-col cols="12" md="12" lg="4" xl="3">
          <b-card class="invoice-overview mb-0">
            <div v-if="isLoading">
              <b-skeleton width="30%" height="30px" class="mb-4"></b-skeleton>
              <b-skeleton height="30px"></b-skeleton>
            </div>
            <div v-else>
              <h3 class="mb-4 bold">Invoice Overview</h3>
              <b-button
                pill
                variant="secondary"
                block
                @click="currentView = 'history'"
              >
                View invoice history
              </b-button>
            </div>
            <div v-if="previousInvoice">
              <div
                class="py-6 d-flex justify-content-between align-items-center"
              >
                <h3 class="mb-0">Previous Invoice</h3>
                <small class="text-muted">
                  {{
                    parseDate(
                      previousInvoice.coveredperiod.startdate_unix,
                      "MM/dd/yy",
                      true
                    )
                  }}
                  -
                  {{
                    parseDate(
                      previousInvoice.coveredperiod.enddate_unix,
                      "MM/dd/yy",
                      true
                    )
                  }}
                </small>
              </div>
              <div
                class="mb-4 pb-4"
                style="
                  border-bottom: 1px solid #ff7a37;
                  max-height: 1000px;
                  overflow-y: auto;
                "
              >
                <div
                  class="pb-2 d-flex justify-content-between align-items-end"
                >
                  <div>
                    <small class="text-muted">New Patients</small>
                    <p class="mb-0">Total</p>
                  </div>
                  <div>{{ previousInvoice.patients.new }}</div>
                </div>
                <div
                  class="pb-5 d-flex justify-content-between align-items-end"
                >
                  <div>
                    <small class="text-muted">New Patients</small>
                    <p class="mb-0">Amount Earned</p>
                  </div>
                  <div>{{ parseCurrencyUSD(previousInvoice.earning.new) }}</div>
                </div>
                <div
                  class="pb-2 d-flex justify-content-between align-items-end"
                >
                  <div>
                    <small class="text-muted">Follow-ups Completed</small>
                    <p class="mb-0">Total</p>
                  </div>
                  <div>{{ previousInvoice.patients.followup }}</div>
                </div>
                <div
                  class="pb-5 d-flex justify-content-between align-items-end"
                >
                  <div>
                    <small class="text-muted">Follow-ups Completed</small>
                    <p class="mb-0">Amount Earned</p>
                  </div>
                  <div>
                    {{ parseCurrencyUSD(previousInvoice.earning.followup) }}
                  </div>
                </div>
                <div
                  class="pb-2 d-flex justify-content-between align-items-end"
                >
                  <div>
                    <small class="text-muted">Paid Messages</small>
                    <p class="mb-0">Total</p>
                  </div>
                  <div>{{ previousInvoice.patients.messageadjusted }}</div>
                </div>
                <div
                  class="pb-5 d-flex justify-content-between align-items-end"
                >
                  <div>
                    <small class="text-muted">Paid Messages</small>
                    <p class="mb-0">Amount Earned</p>
                  </div>
                  <div>
                    {{
                      parseCurrencyUSD(
                        previousInvoice.earning.messageadjusted || 0
                      )
                    }}
                  </div>
                </div>
              </div>
              <div
                class="mb-6 d-flex justify-content-between align-items-center"
              >
                <p class="mb-0">Total</p>
                <div>{{ parseCurrencyUSD(previousInvoice.earning.total) }}</div>
              </div>
            </div>
            <!-- <template v-slot:footer>
              <b-button pill variant="outline-secondary" block>
                Download invoice
              </b-button>
              <b-button
                variant="link"
                block
                class="pb-0"
                style="box-shadow: none !important"
              >
                <span class="text-secondary d-inline-block btn-patient-reply">
                  View full invoice
                </span>
              </b-button>
            </template> -->
          </b-card>
        </b-col>
      </b-row>
    </b-col>

    <!-- HISTORY -->
    <b-col cols="12" v-show="currentView === 'history'" class="mb-6">
      <b-row class="content">
        <b-col cols="12" v-if="currentInvoice">
          <b-row class="px-2 mb-3">
            <b-col
              cols="12"
              md="6"
              xl="3"
              class="pl-0 d-flex align-items-stretch"
            >
              <CardEarningStat>
                <template #card-title> Total Money Collected </template>
                <template #card-text>
                  <b-skeleton
                    v-if="isLoading && currentInvoice"
                    width="20%"
                    height="30px"
                  ></b-skeleton>
                  <span v-else>
                    {{ parseCurrencyUSD(currentInvoice.earning.alltime) }}
                  </span>
                </template>
                <template #card-subtext>
                  <b-skeleton
                    v-if="isLoading && currentInvoice"
                    width="40%"
                    height="15px"
                  ></b-skeleton>
                  <p v-else class="mb-2">
                    since
                    {{
                      parseDate(currentInvoice.doctorstartdate, "MMMM d, yyyy")
                    }}
                  </p>
                </template>
              </CardEarningStat>
            </b-col>
            <b-col
              cols="12"
              md="6"
              xl="3"
              class="pl-0 d-flex align-items-stretch"
            >
              <CardEarningStat>
                <template #card-title>
                  Total Patients Verified - All Time
                </template>
                <template #card-text>
                  <b-skeleton
                    v-if="isLoading && currentInvoice"
                    width="20%"
                    height="30px"
                  ></b-skeleton>
                  <span v-else>
                    {{ patientAllTimeCount }}
                  </span>
                </template>
              </CardEarningStat>
            </b-col>

            <b-col
              cols="12"
              md="6"
              xl="3"
              class="pl-0 d-flex align-items-stretch"
            >
              <CardEarningStat>
                <template #card-title>
                  Total New Patients this Period
                </template>
                <template #card-text>
                  <b-skeleton
                    v-if="isLoading && currentInvoice"
                    width="20%"
                    height="30px"
                  ></b-skeleton>
                  <span v-else>
                    {{ currentInvoice.patients.new }}
                  </span>
                </template>
              </CardEarningStat>
            </b-col>
            <b-col
              cols="12"
              md="6"
              xl="3"
              class="pl-0 d-flex align-items-stretch"
            >
              <CardEarningStat>
                <template #card-title>
                  Follow-ups Completed this Period
                </template>
                <template #card-text>
                  <b-skeleton
                    v-if="isLoading && currentInvoice"
                    width="20%"
                    height="30px"
                  ></b-skeleton>
                  <span v-else>
                    {{ currentInvoice.patients.followup }}
                  </span>
                </template>
              </CardEarningStat>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" style="padding: 20px">
          <div class="pt-4 pb-2">
            <h3 class="mb-0">Current Invoice</h3>
            <small
              v-if="currentInvoice.coveredperiod.startdate_unix"
              class="text-muted"
            >
              {{
                parseDate(
                  currentInvoice.coveredperiod.startdate_unix,
                  "MM/dd/yy",
                  true
                )
              }}
              -
              {{
                parseDate(
                  currentInvoice.coveredperiod.enddate_unix,
                  "MM/dd/yy",
                  true
                )
              }}
            </small>
          </div>
          <TableEarning
            :fields="currentInvoiceFields"
            :items="currentPatientsInvoiceItems"
            :no-header="true"
            scroll-height="calc(100vh - 70vh)"
            scrollable
          />
          <div class="d-flex justify-content-end mt-4 pr-4">
            <div class="d-flex align-items-center">
              <h3 class="mb-0 mr-6">Total</h3>
              <h2 class="mb-0 text-primary">
                {{ totalPatientsInvoiceAmount }}
              </h2>
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="pb-2">
            <h3 class="mb-0">Invoice History</h3>
          </div>
          <TableEarning
            :fields="invoiceHistoryFields"
            :items="invoiceHistoryItems"
            :no-header="true"
            scroll-height="calc(100vh - 70vh)"
            scrollable
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
import { utcToZonedTime } from "date-fns-tz";
import { format, fromUnixTime, differenceInCalendarMonths } from "date-fns";
import CardEarningStat from "@/components/earnings/CardEarningStat.vue";
import TableEarning from "@/components/earnings/TableEarning.vue";
export default {
  name: "earnings",
  components: {
    CardEarningStat,
    TableEarning,
  },
  data() {
    return {
      currentView: "earnings",
      earningsTableFilter: "All",
      allTimePatientsFields: [
        { key: "fullname", label: "Name", sortable: true },
        { key: "accepteddate", label: "Accepted Date", sortable: true },
        { key: "monthsactive", label: "Months Active", sortable: false },
      ],
      newPatientsFields: [
        { key: "fullname", label: "Name", sortable: true },
        { key: "accepteddate", label: "Accepted Date", sortable: true },
        { key: "monthsactive", label: "Months Active", sortable: false },
      ],
      followupPatientsField: [
        { key: "fullname", label: "Name", sortable: true },
        { key: "email", label: "Email", sortable: false },
      ],
      paidMessagesFields: [
        { key: "fullname", label: "Name", sortable: true },
        { key: "email", label: "Email", sortable: false },
      ],
      currentInvoiceFields: [
        { key: "patientstatus", label: "Patient Status", sortable: false },
        { key: "patient", label: "Patient", sortable: true },
        { key: "dateaccepted", label: "Date Accepted", sortable: true },
        {
          key: "amount",
          label: "Amount",
          sortable: false,
          class: "text-right",
        },
      ],
      invoiceHistoryFields: [
        { key: "billingperiod", label: "Billing Period", sortable: true },
        {
          key: "totalfollowup",
          label: "Follow-up Completed",
          class: "text-right",
        },
        {
          key: "followupearned",
          label: "Follow-up Earnings",
          class: "text-right",
        },
        { key: "totalnew", label: "Total New", class: "text-right" },
        {
          key: "newearned",
          label: "New Patient Earnings",
          class: "text-right",
        },
        { key: "totalinvoice", label: "Total Invoice", class: "text-right" },
        { key: "download", label: "Action", class: "text-right" },
      ],

      isLoading: false,

      patientAllTimeCount: 0,
      newPatientsCount: 0,
      followupPatientsCount: 0,
      paidMessagesCount: 0,

      currentPageAllTime: 1,
      currentPageNewPatients: 1,
      currentPageFollowupPatients: 1,
      currentPagePaidMessages: 1,
    };
  },
  computed: {
    invoiceHistoryItems() {
      return this.invoiceHistory.map((invoice) => ({
        billingperiod: `${this.parseDate(
          invoice.coveredperiod.startdate_unix,
          "MM/dd/yy",
          true
        )} - ${this.parseDate(
          invoice.coveredperiod.enddate_unix,
          "MM/dd/yy",
          true
        )}`,
        totalfollowup: invoice.patients.followup,
        followupearned: this.parseCurrencyUSD(invoice.earning.followup),
        totalnew: invoice.patients.new,
        newearned: this.parseCurrencyUSD(invoice.earning.new),
        totalinvoice: this.parseCurrencyUSD(invoice.earning.total),
        id: invoice.id,
      }));
    },
    currentPatientsInvoiceItems() {
      return this.currentPatientsInvoice.map((invoice) => ({
        patientstatus: invoice.status,
        patient: `${invoice.firstname} ${invoice.lastname}`,
        dateaccepted: invoice.date
          ? this.parseDate(invoice.date, "MM/dd/yy hh:mm aaaa")
          : "-",
        amount: this.parseCurrencyUSD(invoice.amount),
      }));
    },
    allTimePatientsList() {
      return this.patientList.map((patient) => ({
        fullname: `${patient.firstname} ${patient.lastname}`,
        accepteddate: patient.dateaccepted
          ? this.parseDate(patient.dateaccepted, "MM/dd/yy")
          : "-",
        enddate: "-",
        monthsactive:
          patient.status !== "Inactive" && patient.dateaccepted
            ? differenceInCalendarMonths(
                new Date(),
                fromUnixTime(patient.dateaccepted)
              )
            : "-",
      }));
    },
    cleanedNewPatientList() {
      return this.newPatientList.map((patient) => ({
        fullname: `${patient.firstname} ${patient.lastname}`,
        accepteddate: patient.dateaccepted
          ? this.parseDate(patient.dateaccepted, "MM/dd/yy")
          : "-",
        enddate: "-",
        monthsactive:
          patient.status !== "Inactive" && patient.dateaccepted
            ? differenceInCalendarMonths(
                new Date(),
                fromUnixTime(patient.dateaccepted)
              )
            : "-",
      }));
    },
    cleanedFollowupPatientList() {
      return this.followupPatientList.map((patient) => ({
        fullname: `${patient.firstname} ${patient.lastname}`,
        email: patient.email,
      }));
    },
    cleanedPaidMessagesList() {
      return this.paidMessagesList.map((patient) => ({
        fullname: `${patient.firstname} ${patient.lastname}`,
        email: patient.email,
      }));
    },
    totalPatientsInvoiceAmount() {
      const totalAmount = this.currentPatientsInvoice.reduce((prev, curr) => {
        return prev + curr.amount;
      }, 0);
      return this.parseCurrencyUSD(totalAmount);
    },
    previousInvoice() {
      if (this.invoiceHistory.length > 1) {
        return this.invoiceHistory[1];
      } else {
        return false;
      }
    },
    ...mapState("earnings", [
      "currentInvoice",
      "invoiceHistory",
      "currentPatientsInvoice",
      "patientList",
      "newPatientList",
      "followupPatientList",
      "paidMessagesList",
    ]),
  },
  async created() {
    window.analytics.page();
    this.isLoading = true;
    const allTimePatientListData = await this.$store.dispatch(
      "earnings/fetchPatientsAllTime",
      1
    );
    const newPatientListData = await this.$store.dispatch(
      "earnings/fetchNewPatientsList",
      1
    );
    const followupPatientListData = await this.$store.dispatch(
      "earnings/fetchFollowupPatientsList",
      1
    );
    await this.$store.dispatch("earnings/fetchCurrentInvoice");
    await this.$store.dispatch("earnings/fetchCurrentPatientsInvoice");
    await this.$store.dispatch("earnings/fetchInvoiceHistory");
    const paidMessagesData = await this.$store.dispatch(
      "earnings/fetchPaidMessagesList",
      1
    );
    this.patientAllTimeCount = Number(allTimePatientListData.count);
    this.newPatientsCount = Number(newPatientListData.count);
    this.followupPatientsCount = Number(followupPatientListData.count);
    this.paidMessagesCount = Number(paidMessagesData.count);
    this.isLoading = false;
  },
  methods: {
    parseCurrencyUSD(amount) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(amount);
    },
    parseDate(date, stringFormat, isZoned) {
      if (isZoned) {
        return format(utcToZonedTime(fromUnixTime(date), "GMT"), stringFormat);
      } else {
        return format(fromUnixTime(date), stringFormat);
      }
    },
    filterPatientList(patientList, status) {
      return patientList.filter((patient) => {
        return status === "All" ? true : patient.status === status;
      });
    },
    handlePaginationClick(event, pageNumber) {
      if (this.earningsTableFilter === "All") {
        this.currentPageAllTime = pageNumber;
        this.$store.dispatch("earnings/fetchPatientsAllTime", pageNumber);
      } else if (this.earningsTableFilter === "New") {
        this.currentPageNewPatients = pageNumber;
        this.$store.dispatch("earnings/fetchNewPatientsList", pageNumber);
      } else if (this.earningsTableFilter === "Paid Messages") {
        this.followupPatientsCount = pageNumber;
        this.$store.dispatch("earnings/fetchFollowupPatientsList", pageNumber);
      } else if (this.earningsTableFilter === "Paid Messages") {
        this.currentPagePaidMessages = pageNumber;
        this.$store.dispatch("earnings/fetchPaidMessagesList", pageNumber);
      }
    },
  },
};
</script>

<style scoped>
/deep/.invoice-overview .card-header,
/deep/.invoice-overview .card-footer {
  border: none;
}
</style>

<style lang="scss" scoped>
.content {
  > div {
    padding: 20px;
    background-color: rgb(243, 247, 248);
  }
}

.invoice-overview {
  background-color: $quaternary;
  border: none;
}

.darkmode .invoice-overview {
  background-color: rgba(255, 236, 202, 0.05);
}

.invoice-overview {
  .card-header {
    border-bottom: 0;
  }

  .card-body {
    height: calc(100vh - 350px);
    overflow: auto;
  }
}

@media (max-width: 768px) {
  .invoice-overview {
    .card-body {
      height: auto;
    }
  }
}

.invoice-overview .card-body {
  @include media-breakpoint-up(md) {
    height: calc(65vh);
  }
}

.status-pill {
  background: #f2f2f2;
  border-radius: 999px;
  padding: 4px 8px;
  margin-left: 0.25rem;
  font-size: 0.75rem;
}

.pill-purple {
  background: #a783ff;
  color: #edffe1;
}

.pill-monarch {
  background: #ff8d40;
  color: #ffecca;
}

.pill-dark-cyan {
  background: $primary;
  color: #ccffff;
}

.text-purple {
  color: #a783ff;
}

.text-monarch {
  color: #ff8d40;
}

.text-dark-cyan {
  color: $primary;
}
</style>
