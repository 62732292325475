<template>
  <b-card header-tag="header" no-body class="mb-0 table-card" style="border: 0">
    <template v-slot:header v-if="!noHeader">
      <b-row align-v="center">
        <b-col v-if="title" :cols="hasSearch ? 'auto' : ''">
          <strong>{{ title }}</strong>
        </b-col>
        <b-col v-if="hasSearch">
          <div class="input-group input-group-flush">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fe fe-search"></i>
              </span>
            </div>
            <input
              v-model="filter"
              class="form-control list-search"
              type="search"
              placeholder="Search"
            />
          </div>
        </b-col>
        <b-col cols="12" md="auto">
          <b-row align-v="center">
            <b-col>
              <template v-if="filter"
                >Showing <b>{{ rows }}</b>
                <template v-if="rows == 1 || rows == 0"> result</template
                ><template v-else> results</template> for “{{
                  filter
                }}”</template
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>
    <b-table
      ref="selectableTable"
      responsive
      select-mode="single"
      :selectable="isSelectable"
      :sticky-header="scrollable ? scrollHeight : false"
      :fields="fields"
      :items="items"
      @filtered="onFiltered"
      :filter="hasSearch ? filter : ''"
      :current-page="hasPagination ? currentPage : ''"
      :per-page="hasPagination ? itemPerPage : ''"
      @row-selected="onRowSelected"
      @scroll.native="onScroll"
      :class="['table-nowrap billing-table', className]"
      :sort-compare="sortCompare"
    >
      <template v-slot:cell(status)="data">
        <p class="mb-0">
          <span
            class="mr-3 dot dot-green"
            v-if="data.item.status === 'Follow-up Completed'"
          ></span>
          <span
            class="mr-3 dot dot-red"
            v-else-if="data.item.status === 'Inactive'"
          ></span>
          <span
            class="mr-3 dot dot-yellow"
            v-else-if="data.item.status === 'New'"
          ></span>
          <span>{{ data.item.status }}</span>
        </p>
      </template>
      <template v-slot:cell(patientstatus)="data">
        <p class="mb-0">
          <span
            class="mr-3 dot dot-green"
            v-if="data.item.patientstatus === 'Follow-up Completed'"
          ></span>
          <span
            class="mr-3 dot dot-red"
            v-else-if="data.item.patientstatus === 'Inactive'"
          ></span>
          <span
            class="mr-3 dot dot-yellow"
            v-else-if="data.item.patientstatus === 'New'"
          ></span>
          <span>{{ data.item.patientstatus }}</span>
        </p>
      </template>
      <template v-slot:cell(payoutstatus)="data">
        <p class="mb-0">
          <span
            class="mr-3 dot dot-green"
            v-if="data.item.payoutstatus === 'Cleared'"
          ></span>
          <span>{{ data.item.payoutstatus }}</span>
        </p>
      </template>
      <template v-slot:cell(download)="data">
        <a
          class="text-secondary tw-cursor-pointer"
          @click="onDownloadInvoice(data.item.id)"
        >
          Download
        </a>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { PatientService } from "@/services/patient.service";
import { format, fromUnixTime, parse, isBefore, isAfter } from "date-fns";

export default {
  props: {
    title: {
      type: String,
    },
    className: {
      type: String,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    scrollHeight: {
      type: String,
      default: "400px",
    },
    hasPagination: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    itemPerPage: {
      type: Number,
      default: 5,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rows: 1,
      patientid: "",
      allergies: "",
      prescriptionid: "",
      prescription: {},
      loading: true,
      currentPage: 1,
      filter: null,
      selected: [],
      rowSelect: [],
      selectAll: false,
      alert: {},
      iframeHeight: "",
    };
  },
  mounted() {
    this.$forceUpdate();
    this.matchHeight();

    window.addEventListener("resize", () => {
      this.matchHeight();
    });
  },
  created() {
    this.rows = this.items.length;
  },
  methods: {
    sortCompare(a, b, key) {
      if (key === "accepteddate") {
        const dateA = parse(a[key], "MM/dd/yy", new Date());
        const dateB = parse(b[key], "MM/dd/yy", new Date());
        if (isBefore(dateA, dateB)) {
          return -1;
        } else if (isAfter(dateA, dateB)) {
          return 1;
        } else {
          return 0;
        }
      }

      if (key === "dateaccepted") {
        const dateA = parse(a[key], "MM/dd/yy hh:mm aaaa", new Date());
        const dateB = parse(b[key], "MM/dd/yy hh:mm aaaa", new Date());
        if (isBefore(dateA, dateB)) {
          return -1;
        } else if (isAfter(dateA, dateB)) {
          return 1;
        } else {
          return 0;
        }
      }
    },
    matchHeight() {
      let appHeight = window.innerHeight - 18;
      this.iframeHeight = appHeight;
    },
    onRowSelected(rows) {
      if (Object.keys(rows).length > 0) {
        this.rowSelect = rows;
        this.$emit("selectedPrescription", this.rowSelect[0].data);
      } else {
        this.$emit("selectedPrescription", {});
      }
    },
    onFiltered(filteredItems) {
      if (this.filter) {
        this.rows = filteredItems.length;
        return;
      } else {
        this.rows = this.items.length;
        return;
      }
    },
    onScroll(e) {
      this.$emit("scroll", e);
    },
    parseDate(date, type, delimiter = "-") {
      return format(
        type === "unix" ? fromUnixTime(date) : new Date(date),
        `MM${delimiter}dd${delimiter}yyyy`
      );
    },

    async onDownloadInvoice(billingsummaryid) {
      try {
        const response = await this.$store.dispatch(
          "earnings/downloadInvoice",
          billingsummaryid
        );
        window.open(response);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
  watch: {
    selected(newValue) {
      this.selectAll = newValue.length == this.rows ? true : false;
      if (this.hasPagination)
        this.selectAll = newValue.length == this.indexLast ? true : false;
    },
  },
};
</script>

<style scoped>
/deep/.pagination {
  margin-bottom: 0;
}

/deep/.page-item.active {
  display: none;
}

/deep/.page-link[role="menuitemradio"] {
  display: none;
}

/deep/.page-item.active .page {
  display: none;
}

/deep/.table thead th,
/deep/.table.b-table > thead > tr > .table-b-table-default,
.table.b-table > tbody > tr > .table-b-table-default,
.table.b-table > tfoot > tr > .table-b-table-default {
  background-color: rgb(243, 247, 248);
  border-bottom: 1px solid #1a6a72;
}

/deep/.table.b-table > thead > tr > .table-b-table-default {
  color: #828282;
  text-transform: capitalize;
  font-weight: normal;
  letter-spacing: 0.75px;
}

/deep/.table thead th:focus {
  user-select: none;
}

/deep/.table .table-b-table-default {
  position: relative !important;
}
</style>

<style lang="scss">
.table.b-table > tbody > .table-active,
.table.b-table > tbody > .table-active > th,
.table.b-table > tbody > .table-active > td {
  background-color: $primary;
  color: $white;
}

.b-table-sticky-header > .table.b-table > thead > tr > th {
  border-top: 0;
}

.table thead th {
  font-size: 12px;
}
</style>

<style lang="scss" scoped>
.card.table-card {
  border: 0;
  background-color: rgb(243, 247, 248);
}

.table thead th {
  border: 0;
}

.table tbody td {
  border-bottom: 0;
  border-top: 1px solid rgba(26, 106, 114, 0.2);
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.dot-green {
  background-color: $green;
}

.dot-red {
  background-color: $red;
}

.dot-yellow {
  background-color: #f5d83e;
}
</style>
